import React, { useEffect } from 'react';
import atomize from '@quarkly/atomize';
import { Box, Text } from '@quarkly/widgets';

const AlertOnLeave = ({
	message
}) => {
	useEffect(() => {
		const handleBeforeUnload = e => {
			const confirmationMessage = message || 'Nem mentett változtatásaid vannak. Biztosan el akarsz navigálni?';
			e.returnValue = confirmationMessage;
			return confirmationMessage;
		};

		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [message]);
	return <Box position="absolute" opacity="0" />;
};

export default atomize(AlertOnLeave)({
	name: 'AlertOnLeave',
	description: {
		en: 'AlertOnLeave — prompts a warning when the user tries to leave the page.'
	},
	propInfo: {
		message: {
			title: 'Alert Message',
			description: 'Custom message to display when the user tries to leave the page',
			control: 'input',
			type: 'text',
			category: 'Main',
			weight: 1
		}
	}
});