import React, { useState, useEffect, useRef } from 'react';
import atomize from "@quarkly/atomize";
import { Box, Text } from "@quarkly/widgets";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { RingLoader } from 'react-spinners';
import TagManager from "react-gtm-module"; // Interceptor function to log all XMLHttpRequests

/*(function() {
  const open = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function() {
    this.addEventListener('load', function() {
      console.log('Request URL:', this.responseURL);
    });
    open.apply(this, arguments);
  };
})();*/

const Paypal = ({
	name,
	email,
	clientId,
	price,
	itemName,
	currencyCode,
	itemDescription,
	onSuccess,
	isTrueSession,
	...props
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isFormValid, setIsFormValid] = useState(false);
	const [retryCount, setRetryCount] = useState(0);
	const maxRetries = 3; // Set the maximum number of retries

	const paypalRef = useRef();
	useEffect(() => {
		setIsLoading(false);
	}, []);
	useEffect(() => {
		// Extend this validation as needed, for now a simple example
		const validateEmail = email => {
			return email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i);
		};

		setIsFormValid(name.trim() !== "" && validateEmail(email));
	}, [name, email]);

	const sendInitPurhcaseEvent = () => {
		const _sessionId = localStorage.getItem('sessionId');

		const tagManagerArgs = {
			dataLayer: {
				event: 'initialise purchase',
				// Use a specific event name
				transactionId: _sessionId // Example data

			}
		};
		TagManager.dataLayer(tagManagerArgs); //console.log("init purchase event ", tagManagerArgs);
	};

	const handleError = error => {
		console.error('Payment Error:', error); //console.log('Retry count:', retryCount);

		if (retryCount < maxRetries) {
			setRetryCount(retryCount + 1);
			setTimeout(() => {
				if (paypalRef.current) {
					//console.log('Retrying payment...');
					paypalRef.current.click();
				}
			}, 3000); // Retry after 3 seconds
		} else {
			console.error('Maximum retry attempts reached.');
		}
	};

	const logAndHandleError = (error, message) => {
		console.error(`${message}:`, error);
		handleError(error);
	};

	return <PayPalScriptProvider options={{
		"client-id": clientId,
		currency: currencyCode,
		locale: "hu_HU"
	}}>
		      
		<Box {...props}>
			        
			{isLoading ? <Box display="grid" justify-items="center">
				            
				<RingLoader color="#2A9D8F" />
				            
				<Text margin="30px" font="--lead">
					Folyamatban...
				</Text>
				          
			</Box> : <PayPalButtons
				ref={paypalRef}
				style={{
					layout: 'vertical',
					label: ""
				}} // Adjust styling as necessary

				disabled={!isTrueSession}
				createOrder={async (data, actions) => {
					const orderData = {
						purchase_units: [{
							amount: {
								currency_code: currencyCode,
								value: price,
								breakdown: {
									item_total: {
										currency_code: currencyCode,
										value: price
									}
								}
							},
							items: [{
								name: itemName,
								description: itemDescription,
								unit_amount: {
									currency_code: currencyCode,
									value: price
								},
								quantity: "1"
							}]
						}],
						application_context: {
							shipping_preference: "NO_SHIPPING"
						}
					}; //console.log('Creating order with data:', orderData);

					try {
						return await actions.order.create(orderData);
					} catch (error) {
						logAndHandleError(error, 'Order creation error');
						throw error; // Rethrow error to propagate to onError
					}
				}}
				onApprove={async (data, actions) => {
					//console.log('Payment approved, capturing order...');
					try {
						const details = await actions.order.capture(); // Reset retry count on success

						setRetryCount(0); // onSuccess callback implementation
						//console.log('Order captured successfully.');

						onSuccess(details, data);
					} catch (error) {
						logAndHandleError(error, 'Order capture error');
						throw error; // Rethrow error to propagate to onError
					}
				}}
				onError={err => {
					logAndHandleError(err, 'onError callback called');
				}} // Attach handleError to onError

				onCancel={() => {
					console.log('Payment cancelled by user.');
				}} // Only log cancellation, don't handle as an error

			/>}
			      
		</Box>
		    
	</PayPalScriptProvider>;
};

export default atomize(Paypal)({
	// Example configuration
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: "QuarklyPaypalButton — A custom PayPal button component",
	propInfo: {
		clientId: {
			title: "Client ID",
			control: "input",
			category: "Main",
			weight: 1
		} // Add other prop configurations here

	}
}, {// Styles or other options
});