import React, { useEffect, useState, useRef } from "react";
import { useOverrides } from "@quarkly/components";
import { Section, Box, Sectionm, Icon, Text } from "@quarkly/widgets";
import { motion, useMotionValue, useTransform, useMotionValueEvent, useDragControls, animate } from "framer-motion";
const MotionBox = motion(Box);
const defaultProps = {
	"padding": "0 0px 0 0px",
	editMode: "Off",
	"max-width": "600px",
	"margin": "5px auto 5px auto",
	"min-height": "123px",
	"border-radius": "15px",
	"box-shadow": "--xl",
	"background": "#ffffff",
	"border-width": "2px",
	"border-style": "solid",
	"border-color": "--color-light"
};
const overrides = {
	"ClickAreaWrapper": {
		"props": {
			"min-width": "100%",
			"min-height": "100%",
			"position": "absolute",
			"display": "grid",
			"grid-template-columns": "1fr 1fr 1fr 1fr 1fr",
			"opacity": "0.5",
			"z-index": "1",
			"align-content": "center",
			"justify-items": "center",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"OuterMotionBox": {
		"props": {
			"display": "grid",
			"position": "relative",
			"justify-items": "center",
			"align-items": "center",
			"z-index": "0"
		}
	},
	"ClickArea1": {
		"props": {
			"border-radius": "50%",
			"width": "45px",
			"height": "45px",
			"background": "#d1d1d1",
			"border-width": "1px"
		}
	},
	"ClickArea2": {
		"props": {
			"border-radius": "50%",
			"width": "40px",
			"height": "40px",
			"background": "#d1d1d1"
		}
	},
	"ClickArea3": {
		"props": {
			"border-radius": "50%",
			"width": "35px",
			"height": "35px",
			"background": "#d1d1d1",
			"border-width": "1px"
		}
	},
	"ClickArea4": {
		"props": {
			"border-radius": "50%",
			"width": "40px",
			"height": "40px",
			"background": "#d1d1d1",
			"border-width": "1px"
		}
	},
	"ClickArea5": {
		"props": {
			"border-radius": "50%",
			"width": "45px",
			"height": "45px",
			"background": "#d1d1d1",
			"border-width": "1px"
		}
	},
	"SVG": {
		"props": {
			"align-items": "center",
			"justify-items": "center",
			"align-content": "center",
			"justify-content": "center",
			"display": "grid",
			"max-width": "100%",
			"width": "40px",
			"height": "45px"
		}
	},
	"InnerMotionBox": {
		"props": {
			"padding": "15px 15px 15px 15px",
			"border-radius": "50%",
			"max-width": "100%",
			"position": "relative",
			"z-index": "2",
			"width": "72px",
			"height": "72px",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "rgba(22, 38, 63, 0.3)",
			"box-shadow": "--l",
			"background": "transparent radial-gradient(circle at center,#ffffff 0%,rgba(255, 255, 255, 0.6) 100%)"
		}
	},
	"MainWrapper": {
		"props": {
			"width": "360px",
			"transform": "scale(0.6)"
		}
	},
	"HorizontalLine": {
		"props": {
			"position": "absolute",
			"min-width": "80%",
			"min-height": "10%",
			"z-index": "-1",
			"background": "#d1d1d1",
			"border-width": "1px"
		}
	},
	"SectionContent": {
		"props": {
			"position": "relative",
			"align-items": "center",
			"max-width": "600px",
			"justify-items": "center",
			"grid-gap": "0px"
		}
	},
	"path4": {
		"props": {}
	},
	"QuestionText": {
		"props": {
			"margin": "16px 0px 0 0px",
			"text-align": "center"
		}
	}
};
const propInfo = {
	editMode: {
		title: "Editing mode",
		control: "radio-group",
		variants: ["On", "Off"],
		category: 'Main',
		weight: 1
	}
};

const EnnaQASection = ({
	question = 'Default question text',
	questionId,
	onAnswerUpdate,
	currentAnswer,
	...props
}) => {
	const {
		children,
		override,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [answer, setAnswer] = useState(0);
	const [answerPos, setAnswerPos] = useState(0);
	const [isOnDrag, setIsOnDrag] = useState(false);
	useEffect(() => {
		resetAnswerState();
	}, [questionId]);

	const resetAnswerState = () => {
		if (props.editMode === "Off") {
			onAnswerUpdate(questionId, answer);
		} //console.log('qid', questionId);


		sequence(currentAnswer);
	};

	useEffect(() => {
		if (answer !== 0 && props.editMode === "Off") {
			onAnswerUpdate(questionId, answer);
		}
	}, [answer]);
	useEffect(() => {
		if (!isOnDrag) {
			setAnswer(answerMap[answerPos] || 0);
		}
	}, [answerPos]);
	const answerMap = {
		'-150': 1,
		'-72': 2,
		'0': 3,
		'72': 4,
		'150': 5
	};
	const x = useMotionValue(0);
	useMotionValueEvent(x, "change", value => setAnswerPos(value));
	const xInput = [-150, 0, 150];
	const color = useTransform(x, xInput, ["rgb(230, 41, 79)", "rgb(75, 124, 207)", "rgb(28, 124, 84)"]);
	const tickPath1 = useTransform(x, [-140, -100], [1, 0]);
	const tickPath2 = useTransform(x, [-110, -72, -30], [0, 1, 0]);
	const tickPath3 = useTransform(x, [-40, 0, 40], [0, 1, 0]);
	const tickPath4 = useTransform(x, [30, 60, 110], [0, 1, 0]);
	const tickPath5 = useTransform(x, [100, 140], [0, 1]);
	const dragControls = useDragControls();
	const scope = useRef();

	const sequence = rating => {
		const snapGridX = [-150, -72, 0, 72, 150];
		const targetX = snapGridX[rating - 1];
		animate(x, targetX, {
			duration: 0.5
		});
	};

	return <Section key={Math.random(0, 3)} {...rest}>
		{children}
		<Box {...override('QuestionWrapper')}>
			<Text font="--base" {...override('QuestionText')}>
				{question}
			</Text>
		</Box>
		<Box {...override("MainWrapper")}>
			<MotionBox onPointerDown={event => dragControls.start(event)} {...override('OuterMotionBox')}>
				<MotionBox
					drag="x"
					dragElastic={0.1}
					dragMomentum={false}
					ref={scope}
					dragConstraints={{
						left: -150,
						right: 150
					}}
					dragControls={dragControls}
					cursor={isOnDrag ? "grabbing" : "grab"}
					style={{
						x
					}}
					whileTap={{
						cursor: "grabbing"
					}}
					onDragStart={() => {
						setIsOnDrag(true);
					}}
					onDragEnd={() => {
						setIsOnDrag(false);
					}}
					dragTransition={{
						power: 0.5,
						timeConstant: 100,
						modifyTarget: target => Math.round(target / 72) * 72
					}}
					{...override('InnerMotionBox')}
				>
					<svg {...override('SVG')} className="progress-icon" viewBox="0 0 45 75" style={{}}>
						// number 1
						<motion.path
							fill="none"
							strokeWidth="2"
							stroke={color}
							d="M 26.714 70 L 24.814 70 A 2.705 2.705 0 0 1 24.121 69.916 A 1.97 1.97 0 0 1 23.214 69.4 A 2.008 2.008 0 0 1 22.657 68.303 A 2.864 2.864 0 0 1 22.614 67.8 L 22.614 8.2 L 4.714 22 Q 4.029 22.514 3.306 22.514 A 2.068 2.068 0 0 1 3.064 22.5 Q 2.214 22.4 1.614 21.6 L 0.514 20.2 Q 0 19.514 0 18.792 A 2.068 2.068 0 0 1 0.014 18.55 Q 0.114 17.7 0.914 17.1 L 22.214 0.7 Q 22.739 0.325 23.208 0.175 A 2.183 2.183 0 0 1 23.514 0.1 A 7.317 7.317 0 0 1 24.303 0.014 A 9.044 9.044 0 0 1 24.814 0 L 26.714 0 A 2.705 2.705 0 0 1 27.408 0.084 A 1.97 1.97 0 0 1 28.314 0.6 A 2.008 2.008 0 0 1 28.872 1.697 A 2.864 2.864 0 0 1 28.914 2.2 L 28.914 67.8 Q 28.914 68.8 28.314 69.4 A 2.008 2.008 0 0 1 27.217 69.958 A 2.864 2.864 0 0 1 26.714 70 Z"
							strokeDasharray="0 1"
							style={{
								pathLength: tickPath1
							}}
							{...override('path1')}
						/>
						// number 2
						<motion.path
							fill="none"
							strokeWidth="2"
							stroke={color}
							d="M 43.2 71 L 2.2 71 A 2.705 2.705 0 0 1 1.506 70.916 A 1.97 1.97 0 0 1 0.6 70.4 A 2.008 2.008 0 0 1 0.042 69.303 A 2.864 2.864 0 0 1 0 68.8 L 0 67.3 A 3.746 3.746 0 0 1 0.384 65.663 A 4.575 4.575 0 0 1 0.55 65.35 A 5.821 5.821 0 0 1 0.977 64.722 Q 1.459 64.097 2.237 63.348 A 21.227 21.227 0 0 1 2.5 63.1 L 28.4 37.2 A 79.887 79.887 0 0 0 30.599 35.101 Q 31.712 33.994 32.612 32.994 A 32.205 32.205 0 0 0 34.2 31.1 A 18.99 18.99 0 0 0 35.778 28.714 A 13.738 13.738 0 0 0 36.95 25.95 A 18.289 18.289 0 0 0 37.59 22.593 A 23.191 23.191 0 0 0 37.7 20.3 A 18.764 18.764 0 0 0 37.224 15.948 A 12.504 12.504 0 0 0 33.85 9.8 A 12.508 12.508 0 0 0 28.357 6.689 Q 25.923 6 22.9 6 A 18.959 18.959 0 0 0 19.068 6.37 A 14.237 14.237 0 0 0 15.1 7.8 Q 11.8 9.6 9.9 12.85 A 18.93 18.93 0 0 0 7.966 17.568 A 23.856 23.856 0 0 0 7.4 20.4 A 3.064 3.064 0 0 1 7.259 21.118 A 1.969 1.969 0 0 1 6.5 22.15 Q 5.7 22.7 5.1 22.7 L 3.1 22.7 A 2.92 2.92 0 0 1 2.479 22.638 Q 1.915 22.516 1.55 22.15 A 1.832 1.832 0 0 1 1 20.825 A 2.308 2.308 0 0 1 1 20.8 A 21.57 21.57 0 0 1 1.676 16.119 A 26.518 26.518 0 0 1 2.4 13.75 Q 3.7 10.1 6.35 7 A 19.285 19.285 0 0 1 11.258 2.928 A 23.357 23.357 0 0 1 13.1 1.95 Q 17.065 0.065 22.526 0.002 A 32.862 32.862 0 0 1 22.9 0 Q 28.552 0 32.572 1.604 A 16.205 16.205 0 0 1 35.15 2.9 A 18.263 18.263 0 0 1 39.674 6.822 A 16.357 16.357 0 0 1 41.9 10.4 Q 44 15 44 20.3 A 24.478 24.478 0 0 1 43.543 25.114 A 20.677 20.677 0 0 1 42.8 27.85 Q 41.6 31.3 39.2 34.55 Q 36.838 37.75 33.215 41.094 A 65.225 65.225 0 0 1 33.1 41.2 L 9.7 65 L 43.2 65 A 3.246 3.246 0 0 1 43.868 65.065 Q 44.497 65.197 44.9 65.6 A 1.953 1.953 0 0 1 45.4 66.486 Q 45.5 66.851 45.5 67.3 L 45.5 68.8 Q 45.5 69.8 44.9 70.4 A 1.953 1.953 0 0 1 44.015 70.9 Q 43.649 71 43.2 71 Z"
							strokeDasharray="0 1"
							style={{
								pathLength: tickPath2
							}}
							{...override('path2')}
						/>
						// number 3
						<motion.path
							fill="none"
							strokeWidth="2"
							stroke={color}
							d="M 2.1 52 L 3.5 52 A 3.236 3.236 0 0 1 4.98 52.363 A 3.794 3.794 0 0 1 5.05 52.4 Q 5.724 52.76 6.115 54.007 A 7.043 7.043 0 0 1 6.2 54.3 A 14.944 14.944 0 0 0 7.411 57.527 A 10.939 10.939 0 0 0 10.15 60.95 Q 13 63.3 16.65 64.15 Q 20.3 65 23.9 65 A 26.267 26.267 0 0 0 29.47 64.441 Q 33.524 63.562 36.55 61.3 Q 41.5 57.6 41.5 50.2 A 19.314 19.314 0 0 0 41.128 46.273 Q 40.65 43.971 39.555 42.251 A 9.554 9.554 0 0 0 37.05 39.6 A 15.578 15.578 0 0 0 32.211 37.317 Q 30.117 36.715 27.623 36.508 A 32.986 32.986 0 0 0 24.9 36.4 L 16.1 36.4 A 2.705 2.705 0 0 1 15.406 36.316 A 1.97 1.97 0 0 1 14.5 35.8 A 2.008 2.008 0 0 1 13.942 34.703 A 2.864 2.864 0 0 1 13.9 34.2 L 13.9 32.7 Q 13.9 31.9 14.15 31.35 A 4.945 4.945 0 0 1 14.751 30.363 A 5.483 5.483 0 0 1 14.8 30.3 L 35.4 6 L 5.2 6 Q 4.2 6 3.6 5.4 A 2.008 2.008 0 0 1 3.042 4.303 A 2.864 2.864 0 0 1 3 3.8 L 3 2.3 A 3.246 3.246 0 0 1 3.065 1.632 Q 3.197 1.003 3.6 0.6 A 2.008 2.008 0 0 1 4.697 0.042 A 2.864 2.864 0 0 1 5.2 0 L 42.5 0 A 3.246 3.246 0 0 1 43.168 0.065 Q 43.797 0.197 44.2 0.6 A 1.953 1.953 0 0 1 44.7 1.485 Q 44.8 1.851 44.8 2.3 L 44.8 3.8 A 2.435 2.435 0 0 1 44.741 4.346 A 1.913 1.913 0 0 1 44.55 4.85 Q 44.3 5.3 44 5.7 L 22.9 30.4 L 24.9 30.4 A 37.427 37.427 0 0 1 30.794 30.842 Q 34.108 31.37 36.906 32.532 A 23.464 23.464 0 0 1 36.95 32.55 A 17.855 17.855 0 0 1 42.489 36.116 A 16.645 16.645 0 0 1 44.95 39.1 A 16.958 16.958 0 0 1 47.21 44.578 Q 47.8 47.151 47.8 50.2 Q 47.8 56.8 44.7 61.45 A 19.683 19.683 0 0 1 36.618 68.355 A 23.535 23.535 0 0 1 36.2 68.55 A 27.221 27.221 0 0 1 28.479 70.709 A 34.896 34.896 0 0 1 23.9 71 A 36.994 36.994 0 0 1 18.639 70.643 A 27.721 27.721 0 0 1 13.9 69.55 Q 9.5 68.1 6.45 65.7 A 18.962 18.962 0 0 1 3.322 62.621 A 16.094 16.094 0 0 1 1.75 60.25 Q 0.1 57.2 0 53.9 Q 0 53 0.6 52.5 A 2.133 2.133 0 0 1 1.669 52.031 A 2.877 2.877 0 0 1 2.1 52 Z"
							strokeDasharray="0 1"
							style={{
								pathLength: tickPath3
							}}
							{...override('path3')}
						/>
						// number 4
						<motion.path
							fill="none"
							strokeWidth="2"
							stroke={color}
							d="M 34.3 70 L 32.4 70 Q 31.4 70 30.8 69.4 A 2.008 2.008 0 0 1 30.242 68.303 A 2.864 2.864 0 0 1 30.2 67.8 L 30.2 52 L -2.8 52 A 2.705 2.705 0 0 1 -3.494 51.916 A 1.97 1.97 0 0 1 -4.4 51.4 A 2.008 2.008 0 0 1 -4.958 50.303 A 2.864 2.864 0 0 1 -5 49.8 L -5 48.2 A 0.93 0.93 0 0 1 -4.993 48.1 Q -4.962 47.831 -4.8 47.1 Q -4.6 46.2 -3.9 45.2 L 26.5 1.7 A 3.88 3.88 0 0 1 29.031 0.106 A 6.094 6.094 0 0 1 30.2 0 L 34.3 0 Q 35.3 0 35.9 0.6 A 2.008 2.008 0 0 1 36.458 1.697 A 2.864 2.864 0 0 1 36.5 2.2 L 36.5 46 L 45.6 46 Q 46.6 46 47.2 46.6 A 2.008 2.008 0 0 1 47.758 47.697 A 2.864 2.864 0 0 1 47.8 48.2 L 47.8 49.8 Q 47.8 50.8 47.2 51.4 A 2.008 2.008 0 0 1 46.103 51.958 A 2.864 2.864 0 0 1 45.6 52 L 36.5 52 L 36.5 67.8 Q 36.5 68.8 35.9 69.4 A 2.008 2.008 0 0 1 34.803 69.958 A 2.864 2.864 0 0 1 34.3 70 Z M 30.2 7.1 L 2.9 46 L 30.2 46 L 30.2 7.1 Z"
							strokeDasharray="0 1"
							style={{
								pathLength: tickPath4
							}}
							{...override('path4')}
						/>
						// number 5
						<motion.path
							fill="none"
							strokeWidth="2"
							stroke={color}
							d="M 2.014 50.6 L 3.914 50.6 Q 4.714 50.6 5.364 51 A 1.458 1.458 0 0 1 5.755 51.369 Q 6.047 51.754 6.273 52.426 A 7.776 7.776 0 0 1 6.414 52.9 A 18.419 18.419 0 0 0 7.709 56.468 A 13.86 13.86 0 0 0 10.064 59.9 Q 12.614 62.6 16.014 63.8 Q 19.414 65 23.014 65 A 20.856 20.856 0 0 0 29.08 64.139 A 18.767 18.767 0 0 0 31.964 63 Q 36.014 61 38.414 57.1 A 15.734 15.734 0 0 0 40.418 51.87 A 21.905 21.905 0 0 0 40.814 47.6 A 18.775 18.775 0 0 0 40.355 43.347 A 14.089 14.089 0 0 0 38.414 38.75 Q 36.014 35.1 31.964 33.15 Q 27.914 31.2 23.014 31.2 A 25.087 25.087 0 0 0 20.523 31.316 Q 18.452 31.524 16.914 32.1 Q 14.725 32.921 13.202 33.991 A 11.456 11.456 0 0 0 12.914 34.2 A 114.533 114.533 0 0 1 11.48 35.259 Q 10.705 35.822 10.014 36.3 A 5.453 5.453 0 0 1 9.994 36.314 A 4.978 4.978 0 0 1 7.114 37.2 L 5.314 37.2 A 2.272 2.272 0 0 1 3.764 36.595 A 2.932 2.932 0 0 1 3.714 36.55 A 2.037 2.037 0 0 1 3.312 36.046 Q 3.051 35.574 3.114 35 L 6.114 2.3 Q 6.214 1.2 6.764 0.6 A 1.793 1.793 0 0 1 7.72 0.062 A 2.712 2.712 0 0 1 8.314 0 L 40.914 0 Q 41.914 0 42.514 0.6 A 2.008 2.008 0 0 1 43.072 1.697 A 2.864 2.864 0 0 1 43.114 2.2 L 43.114 3.8 A 2.705 2.705 0 0 1 43.03 4.494 A 1.97 1.97 0 0 1 42.514 5.4 A 2.008 2.008 0 0 1 41.417 5.958 A 2.864 2.864 0 0 1 40.914 6 L 12.214 6 L 10.114 28.6 A 10.925 10.925 0 0 1 10.777 28.216 Q 11.977 27.568 14.314 26.55 Q 17.414 25.2 23.014 25.2 Q 28.014 25.2 32.364 26.65 Q 36.714 28.1 40.064 30.95 Q 43.414 33.8 45.264 37.95 A 20.838 20.838 0 0 1 46.785 43.2 A 28.143 28.143 0 0 1 47.114 47.6 Q 47.114 55.4 43.864 60.6 Q 40.614 65.8 35.164 68.4 A 26.863 26.863 0 0 1 25.886 70.876 A 32.564 32.564 0 0 1 23.014 71 A 32.858 32.858 0 0 1 17.454 70.554 Q 14.27 70.008 11.645 68.789 A 19.421 19.421 0 0 1 10.664 68.3 Q 5.614 65.6 2.914 61.35 A 18.904 18.904 0 0 1 0.589 56.18 A 16.701 16.701 0 0 1 0.014 52.5 A 2.317 2.317 0 0 1 0.001 52.312 Q -0.021 51.547 0.514 51.1 Q 1.114 50.6 2.014 50.6 Z"
							strokeDasharray="0 1"
							style={{
								pathLength: tickPath5
							}}
							{...override('path5')}
						/>
					</svg>
				</MotionBox>
				<Box {...override('ClickAreaWrapper')}>
					<Box onClick={e => sequence(1)} {...override('ClickArea1')} />
					<Box onClick={e => sequence(2)} {...override('ClickArea2')} />
					<Box onClick={e => sequence(3)} {...override('ClickArea3')} />
					<Box onClick={e => sequence(4)} {...override('ClickArea4')} />
					<Box onClick={e => sequence(5)} {...override('ClickArea5')} />
					<Box {...override('HorizontalLine')} />
				</Box>
			</MotionBox>
		</Box>
	</Section>;
};

Object.assign(EnnaQASection, { ...Section,
	defaultProps,
	overrides,
	propInfo
});
export default EnnaQASection;