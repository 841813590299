import React, { useState, useEffect } from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Icon, Button, Strong, LinkBox } from "@quarkly/widgets";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { scrollToId } from './UxUtils';
const MotionBox = motion(Box);
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"text-align": "center",
	"width": "80%",
	"md-width": "80%",
	"sm-width": "100%",
	"border-radius": "24px",
	"id": "price-box",
	"lg-width": "100%"
};
const propInfo = {};
const overrides = {};

const buttonLabel = added => {
	return added ? "-" : "+";
};

const PricePanel = ({
	override,
	purchasedItems,
	priceTotal,
	priceMax = 10,
	priceBase = 3,
	priceAddon1 = 3.5,
	priceAddon2 = 3.5,
	priceAddon3 = 3.5,
	...props
}) => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const {
		scrollY
	} = useViewportScroll();
	const background = useTransform(scrollY, [0, 100], ["rgba(28, 124, 84, 0)", "rgba(28, 124, 84, 0.8)"]);
	const color = useTransform(scrollY, [0, 100], ["rgba(22, 38, 63, 1)", "rgba(255, 255, 255, 1)"]);
	const height = useTransform(scrollY, [0, 100], [0, 80]);
	const [addons, setAddons] = useState({
		addon1: false,
		addon2: false,
		addon3: false
	});

	const calcTotalPrice = () => {
		let total = 0;
		if (addons.addon1) total += parseFloat(priceAddon1);
		if (addons.addon2) total += parseFloat(priceAddon2);
		if (addons.addon3) total += parseFloat(priceAddon3);
		total += parseInt(priceBase);
		const result = total > parseFloat(priceMax) ? parseFloat(priceMax) : total;
		priceTotal(result);
		purchasedItems(addons);
		return result;
	};

	const toggleAddon = addonKey => {
		setAddons(prevFeatures => ({ ...prevFeatures,
			[addonKey]: !prevFeatures[addonKey]
		}));
	}; //const [addonWrapperStyle, setAddonWrapperStyle] = useState({ filter: 'none' });


	const addonWrapperStyle = key => {
		//console.log("addonWrapperStyle", { filter: addons[key] ? 'none' : 'saturate(0%)' })
		return {
			filter: addons[key] ? 'none' : 'saturate(0%)'
		};
	};

	const buttonWrapperStyle = key => {
		//setAddonWrapperStyle({ filter: addons[key] ? 'none' : 'saturate(0%)' })
		return {
			filter: addons[key] ? 'saturate(0%)' : 'none'
		};
	};

	return <Box {...rest}>
		<Box {...override("PPInnerWrapper")}>
			<Text {...override("SideLabel")} />
			<Text {...override("MainTitle")} />
			<MotionBox {...override('Total Price Anim')} id="total-price" style={{
				background,
				height,
				color,
				transition: 'height 0.3s ease, background 0.3s ease'
			}}>
				<Box {...override("TotalPriceWrapper")}>
					<Box {...override("box2")}>
						<Text {...override("text2")} />
					</Box>
					<Box {...override("box3")}>
						<Text {...override("TotalPriceInt")}>
							{Math.trunc(calcTotalPrice()).toLocaleString('en').replace(/,/g, ' ')}
						</Text>
						<Text {...override("TotalPriceDecimals")}>
							{(calcTotalPrice() - Math.trunc(calcTotalPrice())).toFixed(2).slice(2)}
						</Text>
						<Text {...override("TotalCurrencySymbol")}>
							  Ft 
						</Text>
					</Box>
				</Box>
				<Text {...override("text5")} />
			</MotionBox>
			<Box {...override("Get-Basics")}>
				<Box {...override("box6")}>
					<Text {...override("text6")} />
				</Box>
				<Box {...override("box5")}>
					<Icon {...override("icon")} />
				</Box>
			</Box>
			<Box {...override('Addon1 Wrapper')}>
				<Box {...override("Get-Addon1")} style={addonWrapperStyle('addon1')}>
					<Box {...override("box9")}>
						<Text {...override("text8")} />
						<Text {...override("text9")} />
					</Box>
					<Box {...override("box8")}>
						<Icon {...override("icon1")} />
					</Box>
				</Box>
				<Button onClick={() => toggleAddon('addon1')} {...override("button")} style={buttonWrapperStyle('addon1')}>
					<Text {...override("text7")}>
						{buttonLabel(addons.addon1)}
					</Text>
				</Button>
			</Box>
			<Box {...override('Addon2 Wrapper')}>
				<Box {...override("Get-Addon2")} style={addonWrapperStyle('addon2')}>
					<Box {...override("box12")}>
						<Text {...override("text11")} />
						<Text {...override("text12")} />
					</Box>
					<Box {...override("box11")}>
						<Icon {...override("icon2")} />
					</Box>
				</Box>
				<Button onClick={() => toggleAddon('addon2')} {...override("button1")} style={buttonWrapperStyle('addon2')}>
					<Text {...override("text10")}>
						{buttonLabel(addons.addon2)}
					</Text>
				</Button>
			</Box>
			<Box {...override('Addon3 Wrapper')}>
				<Box {...override("Get-Addon3")} style={addonWrapperStyle('addon3')}>
					<Box {...override("box15")}>
						<Text {...override("text14")} />
						<Text {...override("text15")} />
					</Box>
					 
					<Box {...override("box14")}>
						<Icon {...override("icon3")} />
					</Box>
				</Box>
				<Button onClick={() => toggleAddon('addon3')} {...override("button2")} style={buttonWrapperStyle('addon3')}>
					<Text {...override("text13")}>
						{buttonLabel(addons.addon3)}
					</Text>
				</Button>
			</Box>
			<Box {...override("PPFooter")}>
				 
				<Text {...override("text16")} />
				<Icon {...override('demobuttonIco')} />
				<Button {...override('DemoVideoBtn')} onClick={() => scrollToId('demovideo', 1000, 0)} />
			</Box>
		</Box>
		{children}
	</Box>;
};

Object.assign(PricePanel, { ...Box,
	defaultProps,
	overrides,
	propInfo
});
export default PricePanel;