import React, { useEffect, useState } from "react";
import { useOverrides } from "@quarkly/components";
import { Button, Box, LinkBox, Text, List } from "@quarkly/widgets";
import EnneaQaSection from "./EnneaQaSection";
import ProgressBar from "./ProgressBar";
import { motion } from "framer-motion";
import calculateScores from "./CalcResults";
import getQuestions from './GetQuestionsData';
import { scrollToId } from './UxUtils';
import Spinner from './Spinner';
import AlertOnLeave from './AlertOnLeave';
const defaultProps = {};
const MotionBox = motion(Box);
const overrides = {
	"ButtonsWrapper": {
		"props": {
			"display": "flex",
			"justify-content": "center",
			"padding": "24px 0px 24px 0px"
		}
	},
	"Prev Btn": {
		"props": {
			"width": "120px",
			"focus-background": "--color-secondary",
			"focus-box-shadow": "0 0 0 2px --color-secondary"
		}
	},
	"Next Btn": {
		"props": {
			"width": "120px",
			"focus-background": "--color-secondary",
			"focus-box-shadow": "0 0 0 2px --color-secondary"
		}
	},
	"ProgressBar": {
		"props": {
			"position": "sticky",
			"top": 0
		}
	},
	"ResultsBtnWrapper": {
		"props": {
			"text-align": "center",
			"margin": "36px 0px 36px 0px",
			"display": "flex",
			"justify-content": "center"
		}
	},
	"ResultsBtn": {
		"props": {
			"background": "--color-amaranth",
			"font": "--lead",
			"href": "/payment",
			"width": "fit-content",
			"padding": "5px 15px 5px 15px",
			"border-radius": "10px",
			"box-shadow": "--l"
		}
	},
	"OuterWrapper": {
		"props": {
			"max-width": "600px",
			"margin": "0px auto 0px auto"
		}
	},
	"LinkText": {
		"props": {
			"color": "--light"
		}
	}
};

const saveSurveyToLocalStorage = answers => {
	// Assuming 'output' is your object
	const output = calculateScores(answers); // Serialize and save to localStorage

	localStorage.setItem('outputData', JSON.stringify(output));
};

const generateSessionId = () => {
	// Generate a unique session ID
	const sessionId = `session_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
	const expiration = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;
	const output = {
		value: sessionId,
		expires: expiration,
		isComplete: false
	};
	navigator.storage.persisted().then(persisted => {
		if (!persisted) {
			// Request to make storage persistent
			navigator.storage.persist().then(granted => {
				if (granted) {
					console.log("Storage will not be cleared except by explicit user action");
				} else {
					console.log("Storage may be cleared by the browser in low storage conditions");
				}
			});
		}
	}); // Store session ID, expiration date, and isComplete flag in local storage

	localStorage.setItem('sessionData', JSON.stringify(output));
	return output;
};

const updateIsComplete = () => {
	// Retrieve the session data from local storage
	const sessionData = JSON.parse(localStorage.getItem('sessionData')); // Check if session data exists

	if (sessionData) {
		// Update the isComplete value to true
		sessionData.isComplete = true; // Store the updated session data back in local storage

		localStorage.setItem('sessionData', JSON.stringify(sessionData)); //console.log("isComplete has been updated to true");
	} else {
		console.log("No session data found in local storage");
	}
};

const EnneaWrapper = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [questionsData, setQuestionsData] = useState({
		examQuestions: []
	}); // State to store questions data

	const [loading, setLoading] = useState(true); // State to manage loading state

	const [totalQuestions, setTotalQuestions] = useState(0);
	const [answers, setAnswers] = useState([]);
	const [currentBatchIndex, setCurrentBatchIndex] = useState(0); // To track the current batch of questions

	const [sessionId, setSessionId] = useState({});
	const [displayedQuestions, setDisplayedQuestions] = useState([]);
	const batchSize = 10; // Questions per batch

	useEffect(() => {
		const fetchQuestions = async () => {
			try {
				const data = await getQuestions(); // Assuming getQuestions returns a promise

				setQuestionsData(data);
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch questions:", error);
				setLoading(false);
			}
		};

		fetchQuestions();
		setSessionId(generateSessionId());
	}, []);
	useEffect(() => {
		setTotalQuestions(questionsData.examQuestions.length);

		if (questionsData.examQuestions.length > 0) {
			setDisplayedQuestions(questionsData.examQuestions.slice(0, batchSize));
		} //console.log("qdata", questionsData);

	}, [questionsData]);

	const handleAnswerUpdate = (questionId, answer) => {
		const type = questionsData.examQuestions.find(item => item.questionId === questionId)?.type; //console.log('type', type);

		setAnswers(prevAnswers => {
			const existingIndex = prevAnswers.findIndex(ans => ans.questionId === questionId);

			if (existingIndex === -1) {
				return [...prevAnswers, {
					questionId,
					answer,
					type
				}];
			} else {
				const updatedAnswers = [...prevAnswers];
				updatedAnswers[existingIndex].answer = answer;
				return updatedAnswers;
			}
		});
	};

	const handleNext = () => {
		const newBatchIndex = currentBatchIndex + 1;
		setCurrentBatchIndex(newBatchIndex);
		const startIndex = newBatchIndex * batchSize;
		const endIndex = startIndex + batchSize;
		setDisplayedQuestions(questionsData.examQuestions.slice(startIndex, endIndex));
		setIsAnimating(false);
		setTimeout(() => setIsAnimating(true), 0); // Scroll to top

		scrollToId('topnav', 1000, 0);
	};

	const handlePrevious = () => {
		const newBatchIndex = Math.max(0, currentBatchIndex - 1); // Ensure the index doesn't go below 0

		setCurrentBatchIndex(newBatchIndex);
		const startIndex = newBatchIndex * batchSize;
		const endIndex = startIndex + batchSize;
		setDisplayedQuestions(questionsData.examQuestions.slice(startIndex, endIndex));
	};

	async function handleGetResults(sessionId, answers) {
		const url = "https://teszt.sziklaybence.com/.netlify/functions/saveSession"; // Adjust the URL if necessary

		const payload = {
			sessionId,
			answers
		};

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(payload)
			});

			if (!response.ok) {
				throw new Error(`Server responded with status ${response.status}`);
			}

			const result = await response.json(); //console.log('Result from saveSession:', result);

			updateIsComplete();
			return result;
		} catch (error) {
			console.error('Error in handleGetResults:', error);
			throw error;
		}
	}
	/*useEffect(() => {
 	console.log("answers in wrapper", answers);
 }, [answers]);*/


	const [isAnimating, setIsAnimating] = useState(true); // Function to handle animation completion

	const handleAnimationComplete = () => {
		setIsAnimating(false);
	};

	return <Box {...rest}>
		<AlertOnLeave />
		 
		{loading ? <div>
			<Text font="--font-lead" {...override('Loading Label')}>
				A teszt összeállítása folyamatban van...
			</Text>
			 
			<Spinner {...override('loader-spinner')} />
		</div> : <Box {...override('OuterWrapper')}>
			<ProgressBar current={currentBatchIndex} total={totalQuestions / batchSize} {...override('ProgressBar')} />
			<MotionBox key={currentBatchIndex} // Use questionId to uniquely identify each question
			animate={{
				opacity: isAnimating ? 1 : 0
			}} // Toggle opacity based on isAnimating
			initial={{
				opacity: 0
			}} transition={{
				duration: 0.8
			}}>
				{displayedQuestions.map(question => <EnneaQaSection
					key={question.questionId}
					question={question.instruction}
					questionId={question.questionId}
					onAnswerUpdate={handleAnswerUpdate}
					override={override}
					currentAnswer={answers.find(item => item.questionId === question.questionId)?.answer || 3}
				/>)}
			</MotionBox>
			{currentBatchIndex * batchSize > totalQuestions && <Box {...override('ResultsBtnWrapper')}>
				<MotionBox style={{
					boxShadow: "0px -300px 80px 100px rgba(255, 255, 255, .3)"
				}} whileHover={{
					scale: 1.1
				}} whileTap={{
					scale: 0.9
				}}>
					<LinkBox onClick={() => handleGetResults(sessionId.value, answers)} {...override('ResultsBtn')}>
						<Text font="--lead" {...override('LinkText')}>
							Tovább az eredményre
						</Text>
					</LinkBox>
				</MotionBox>
			</Box>}
			<Box {...override('ButtonsWrapper')} display="flex" justifyContent="space-between" marginTop="20px">
				<Button onClick={handlePrevious} disabled={currentBatchIndex === 0} {...override('Prev Btn')}>
					Vissza
				</Button>
				<Button onClick={handleNext} disabled={currentBatchIndex * batchSize > totalQuestions} {...override('Next Btn')}>
					Következő
				</Button>
			</Box>
		</Box>}
	</Box>;
};

Object.assign(EnneaWrapper, { ...Box,
	defaultProps,
	overrides
});
export default EnneaWrapper;