import getData from './AirTableData';

function transformQuestions(inputQuestions) {
	return {
		appExamId: 6667,
		// Assuming this is static or comes from elsewhere
		examId: "EnneagramQuizByWaveDropper",
		// Assuming this is static or comes from elsewhere
		examQuestions: inputQuestions.map(question => {
			const {
				QuestionId,
				Instruction,
				Type
			} = question.fields;
			return {
				questionId: QuestionId,
				instruction: Instruction,
				type: Type,
				answer: 3 //default value

			};
		})
	};
} // Helper function to shuffle array


function shuffleArray(array) {
	/*if(typeof(array) == "array")
 {
   console.log("shuffling array");
 } else {
   console.log("not array", typeof(array));
 }*/
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}

	return array;
}

function transformAndSelectQuestions(inputQuestions) {
	const selectedQuestions = shuffleArray(inputQuestions);
	return transformQuestions(selectedQuestions);
}

const getQuestions = async () => {
	return new Promise((resolve, reject) => {
		getData({
			base: "appbrKbvUmNYS19iS",
			table: "TestQA",
			options: {
				view: "DefaultGrid",
				fields: "",
				filterByFormula: "",
				sort: {
					fields: "",
					directions: ""
				}
			},
			onRecords: records => {
				//console.log(records.records);
				// Place your transformation logic here
				const transformedRecords = transformAndSelectQuestions(records.records);
				resolve(transformedRecords); // Resolve the promise with the transformed data
			},
			onError: error => {
				reject(error); // Reject the promise if there's an error
			}
		});
	});
};

export default getQuestions;