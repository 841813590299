import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitYouTube from "./QuarklycommunityKitYouTube";
const defaultProps = {
	"padding": "65px 0 65px 0",
	"sm-padding": "60px 0 60px 0",
	"quarkly-title": "Video-Demo",
	"background": "--color-light"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "100%",
			"flex-direction": "column",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-align-items": "center",
			"sm-margin": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "16px 40% 16px 16px",
			"lg-padding": "16px 16px 16px 16px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"color": "--primary",
			"font": "normal 600 28px/1.2 --fontFamily-googleLexend",
			"lg-text-align": "center",
			"sm-font": "normal 700 35px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "Mit Kapsz a Vásárlás Után? - Bemutató Videó"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--primary",
			"font": "--base",
			"lg-text-align": "center",
			"sm-margin": "0px 0px 30px 0px",
			"children": "Nézd meg a videót, amely részletesen bemutatja, milyen élmény vár rád a vásárlás után! A személyiségteszt kitöltése és értékelése után egy átfogó, személyre szabott elemzést kapsz, amely mélyreható betekintést nyújt karaktered rejtett oldalaira. Fedezd fel belső motivációidat, félelmeidet és erősségeidet, és tudd meg, hogyan kommunikálhatsz hatékonyabban a különböző személyiségtípusokkal."
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"margin": "0px auto 0px auto",
			"padding": "16px 16px 16px 16px",
			"sm-padding": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex-direction": "column",
			"lg-width": "100%",
			"display": "block",
			"width": "50%"
		}
	},
	"quarklycommunityKitYouTube": {
		"kind": "QuarklycommunityKitYouTube",
		"props": {
			"showOverlay": true,
			"url": "https://youtu.be/HfuFExfREB8",
			"controls": false,
			"font-style": false
		}
	},
	"quarklycommunityKitYouTubeOverride": {
		"kind": "Override",
		"props": {
			"slot": "YouTube Button",
			"background": "--color-light",
			"width": "70px",
			"height": "70px"
		}
	},
	"quarklycommunityKitYouTubeOverride1": {
		"kind": "Override",
		"props": {
			"slot": "YouTube Button Icon",
			"color": "--dark"
		}
	}
};

const VideoDemo = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<QuarklycommunityKitYouTube {...override("quarklycommunityKitYouTube")}>
				<Override {...override("quarklycommunityKitYouTubeOverride")} />
				<Override {...override("quarklycommunityKitYouTubeOverride1")} />
			</QuarklycommunityKitYouTube>
		</Box>
		{children}
	</Section>;
};

Object.assign(VideoDemo, { ...Section,
	defaultProps,
	overrides
});
export default VideoDemo;