import React from "react";
import { useOverrides } from "@quarkly/components";
import { Box } from "@quarkly/widgets";
import { PuffLoader } from 'react-spinners';
const defaultProps = {
	"position": "relative",
	"width": "64px",
	"height": "64px",
	"margin": "0px auto 0px auto"
};
const overrides = {};

const Spinner = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		<PuffLoader color="#487CCF" />
		{children}
	</Box>;
};

Object.assign(Spinner, { ...Box,
	defaultProps,
	overrides
});
export default Spinner;