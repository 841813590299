import React, { useState, useEffect } from 'react';
import { useOverrides } from '@quarkly/components';
import { Section, Box, Text, Link, List, Icon } from '@quarkly/widgets';
import atomize from "@quarkly/atomize";
import AlertOnLeave from './AlertOnLeave';
import ShareButtons from './ShareButtons';
const defaultProps = {};
const overrides = {};
const typeNames = {
	1: 'A Reformer',
	2: 'A Segítő',
	3: 'A Sikerorientált',
	4: 'Az Individualista',
	5: 'A Vizsgálódó',
	6: 'A Lojális',
	7: 'A Rajongó',
	8: 'A Kihívó',
	9: 'A Béketeremtő'
};

const FetchPdfUrls = ({
	type,
	wing,
	addon1,
	addon2,
	addon3,
	override
}) => {
	const {
		rest
	} = useOverrides({
		type,
		addon1,
		addon2,
		addon3
	}, overrides, defaultProps);
	const [urls, setUrls] = useState({});
	const [error, setError] = useState(null);
	useEffect(() => {
		const fetchUrls = async () => {
			try {
				const response = await fetch('https://teszt.sziklaybence.com/.netlify/functions/fetchUrls', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						type,
						addon1,
						addon2,
						addon3
					})
				});
				const data = await response.json();
				setUrls(data);
			} catch (error) {
				setError(error.message);
			}
		};

		fetchUrls();
	}, [type, addon1, addon2, addon3]);

	if (error) {
		return <Text {...override('Error')}>
			Error: 
			{error}
		</Text>;
	}

	return <Section {...rest}>
		      
		<AlertOnLeave />
		      
		<Text {...override('Main Result Title')} />
		      
		<Box {...override('Result Wrapper')}>
			        
			<Box {...override('TypeWrapper')}>
				          
				<Text font="--lead" {...override('PType')}>
					<b>
						Személyiség típus
					</b>
				</Text>
				          
				<Text {...override('PTypeValue')}>
					{type}
					 - 
					{typeNames[type]}
				</Text>
				        
			</Box>
			        
			<Box {...override('WingWrapper')}>
				          
				<Text font="--lead" {...override('PWing')}>
					<b>
						Színezet
					</b>
				</Text>
				          
				<Text {...override('PWingValue')}>
					{wing}
				</Text>
				        
			</Box>
			        
			<ShareButtons shareText={`A személyiség típusom ${typeNames[type]}. Ismerd meg te is a karakteredet, és töltsd le a személyreszabott karakteranalízist!`} shareUrl={"https://teszt.sziklaybence.com"} showButtons={true} {...override('ShareButtons')} />
			      
		</Box>
		      
		<Box {...override('Download Wrapper')}>
			        
			<Text {...override('Title')}>
				{addon1 || addon2 || addon3 ? "Töltsd le a PDF fájlokat az alábbi linkek segítségével" : "Töltsd le a Karakteranalízist az alábbi linkre kattintva."}
			</Text>
			        
			<Box {...override('List Wrapper')}>
				          
				<Icon {...override('Download Ico')} />
				          
				<List {...override('Download List')}>
					            
					{urls.base && <Link href={urls.base} text-decoration-line="none" target="_blank" {...override('Basic')}>
						<Icon {...override('Basic Ico')} />
						Karakteranalízis
					</Link>}
					            
					{urls.addon1 && <Link href={urls.addon1} text-decoration-line="none" target="_blank" {...override('Addon1')}>
						<Icon {...override('Addon1 Ico')} />
						Részletes Karakteranalízis és Fejlődési Pálya
					</Link>}
					            
					{urls.addon2 && <Link href={urls.addon2} text-decoration-line="none" target="_blank" {...override('Addon2')}>
						<Icon {...override('Addon2 Ico')} />
						Profilozói Keretrendszer
					</Link>}
					            
					{urls.addon3 && <Link href={urls.addon3} text-decoration-line="none" target="_blank" {...override('Addon3')}>
						<Icon {...override('Addon3 Ico')} />
						Profilozói Kézikönyv
					</Link>}
					          
				</List>
				        
			</Box>
			        
			<Text {...override('Additional Info')} />
			        
			<Text {...override('Review Disclaimer')} />
			      
		</Box>
		    
	</Section>;
};

export default atomize(FetchPdfUrls)({
	name: "FetchUrlsComponent",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "FetchUrlsComponent — a component to fetch and display URLs based on given parameters"
	},
	propInfo: {
		type: {
			control: "input",
			type: "number",
			category: "Main",
			weight: 1
		},
		addon1: {
			control: "checkbox",
			type: "boolean",
			category: "Main",
			weight: 1
		},
		addon2: {
			control: "checkbox",
			type: "boolean",
			category: "Main",
			weight: 1
		},
		addon3: {
			control: "checkbox",
			type: "boolean",
			category: "Main",
			weight: 1
		}
	}
});