const getOptionFields = fields => {
	return fields.split(',').filter(field => field.trim()).reduce((res, field) => {
		return `${res}&fields[]=${field.trim()}`;
	}, '');
};

const getOptionSort = sort => {
	const fields = sort.fields.split(',').reverse();
	const directions = sort.directions.split(',').reverse();
	return fields.filter(field => field).reduce((res, _, index) => {
		const field = `sort[${index}][field]=${fields[index]}`;
		const direction = `sort[${index}][direction]=${directions[index] || directions[directions.length - 1]}`;
		return `${res}&${field}&${direction}`;
	}, '');
}; // Function for getting request options


const getOption = (opt, key) => {
	let option;

	switch (key) {
		case 'fields':
			option = getOptionFields(opt);
			break;

		case 'sort':
			option = getOptionSort(opt);
			break;

		default:
			option = `&${key}=${opt}`;
	}

	return option;
};

const getData = async ({
	base,
	table,
	options,
	onRecords,
	onError
}) => {
	const query = Object.keys(options).reduce((res, key) => {
		const option = getOption(options[key], key);
		return options[key] ? `${res}${option}` : res;
	}, '');
	let url = `https://api.airtable.com/v0/${base}/${table}?${query}`;
	let allRecords = [];
	let offset = null;

	try {
		do {
			let fetchUrl = url + (offset ? `&offset=${offset}` : '');
			const response = await fetch('https://teszt.sziklaybence.com/.netlify/functions/getAirtableData', {
				method: 'POST',
				body: JSON.stringify({
					url: fetchUrl
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			});

			if (!response.ok) {
				const errorDetails = await response.text();
				throw new Error(`Network response was not ok: ${response.status} ${response.statusText} - ${errorDetails}`);
			}

			const data = await response.json(); //console.log("Data received from Airtable:", data); // Log data for debugging

			allRecords = allRecords.concat(data.records);
			offset = data.offset;
		} while (offset); //console.log("All records received from Airtable:", allRecords); // Log all records for debugging


		onRecords({
			records: allRecords
		}); // Ensure the output format matches the original function
	} catch (error) {
		console.error('Error in getData:', error); // Log the error details

		onError(new Error(`Error fetching data: ${error.message}`));
		return; // or handle error as needed
	}
};
/* End of live version */


export default getData;