// Function to scroll smoothly to the element with the ID 'question'
export const scrollToId = (elementId, duration = 2000, delay = 2000) => {
	//console.log("scrolling");
	setTimeout(() => {
		const targetElement = document.getElementById(elementId);

		if (!targetElement) {
			return;
		}

		const targetPosition = targetElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
		const startPosition = window.pageYOffset;
		const distance = targetPosition - startPosition;
		let startTime = null;

		function animation(currentTime) {
			if (startTime === null) startTime = currentTime;
			const timeElapsed = currentTime - startTime;
			const run = easeInOutCubic(timeElapsed, startPosition, distance, duration);
			window.scrollTo(0, run);
			if (timeElapsed < duration) requestAnimationFrame(animation);
		}

		function easeInOutCubic(t, b, c, d) {
			t /= d / 2;
			if (t < 1) return c / 2 * t * t * t + b;
			t -= 2;
			return c / 2 * (t * t * t + 2) + b;
		}

		requestAnimationFrame(animation);
	}, delay);
};
export const showItem = elementId => {
	if (typeof document !== "undefined") {
		const element = document.getElementById(elementId);

		if (element) {
			//console.log('show item');
			element.style.position = 'static';
			element.style.opacity = '1';
		} else {
			console.log('Element with ID ' + elementId + ' not found.');
		}
	} else {
		console.log('document is not available');
	}
};
export const hideItem = elementId => {
	if (typeof document !== "undefined") {
		const element = document.getElementById(elementId);

		if (element) {
			//console.log('hide item');
			element.style.position = 'absolute';
			element.style.opacity = '0';
			element.style.zIndex = '-10';
		} else {
			console.log('Element with ID ' + elementId + ' not found.');
		}
	} else {
		console.log('document is not available');
	}
};
export default {
	scrollToId,
	showItem,
	hideItem
};