import React from 'react';
import { useOverrides } from '@quarkly/components';
import { Box, Text } from '@quarkly/widgets';
import atomize from "@quarkly/atomize";
import { FacebookShareButton, FacebookMessengerShareButton, FacebookMessengerIcon, LinkedinShareButton, FacebookIcon, LinkedinIcon } from 'react-share';
const overrides = {
	'Facebook Share Button': {
		kind: 'Box',
		props: {
			display: 'inline-block',
			margin: '0 8px'
		}
	},
	'Twitter Share Button': {
		kind: 'Box',
		props: {
			display: 'inline-block',
			margin: '0 8px'
		}
	},
	'LinkedIn Share Button': {
		kind: 'Box',
		props: {
			display: 'inline-block',
			margin: '0 8px'
		}
	}
};

const ShareButtons = ({
	shareText,
	shareUrl,
	showButtons,
	...props
}) => {
	const {
		override,
		rest
	} = useOverrides(props, overrides);

	if (!showButtons) {
		return null;
	}

	const encodedShareText = encodeURIComponent(shareText);
	return <Box {...rest}>
		            
		<Text margin-bottom="16px" font="--font-base">
			Ajánld személyiségtesztünket ismerőseidnek:
		</Text>
		            
		<Box {...override('ButtonsWrapper')}>
			                
			<Box {...override('Facebook Share Button')}>
				                    
				<FacebookShareButton url={shareUrl} hashtag={"#személyiségteszt"}>
					                        
					<FacebookIcon size={32} round />
					                    
				</FacebookShareButton>
				                
			</Box>
			                
			<Box {...override('LinkedIn Share Button')}>
				                    
				<LinkedinShareButton url={shareUrl}>
					                        
					<LinkedinIcon size={32} round />
					                    
				</LinkedinShareButton>
				                
			</Box>
			            
		</Box>
		        
	</Box>;
};

const defaultProps = {
	shareText: 'Check out my personality test result!',
	shareUrl: 'https://example.com/result',
	showButtons: false
};
const propInfo = {
	shareText: {
		title: 'Share Text',
		control: 'input',
		type: 'text',
		category: 'Main',
		weight: 1
	},
	shareUrl: {
		title: 'Share URL',
		control: 'input',
		type: 'text',
		category: 'Main',
		weight: 1
	},
	showButtons: {
		title: 'Show Buttons',
		control: 'checkbox',
		type: 'boolean',
		category: 'Main',
		weight: 1
	}
};
export default atomize(ShareButtons)({
	name: "ShareButtons",
	description: {
		en: "ShareButtons — a component to share your personality test result on social media"
	},
	propInfo,
	defaultProps
});