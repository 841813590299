import React from 'react';
import atomize from '@quarkly/atomize';
import { Box, Text } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components';
import { motion } from "framer-motion";
const MotionBox = motion(Box);

const ProgressBar = ({
	current,
	total,
	...props
}) => {
	const {
		override,
		rest
	} = useOverrides(props, {}, {});
	const percentage = current / total * 100;
	return <Box {...rest}>
		            
		<Box
			position="relative"
			height="24px"
			background="#e0e0de"
			borderRadius="8px"
			overflow="hidden"
			{...override('Wrapper')}
		>
			                
			{current <= total ? <Text
				position="absolute"
				left="50%"
				top="-2px"
				z-index="1"
				transform="translate(-50%, -50%)"
				font="--base"
				{...override('PGLabel')}
			>
				                        
				{current + 1}
				 / 
				{Math.ceil(total)}
				                    
			</Text> : <Text
				position="absolute"
				left="50%"
				top="-2px"
				z-index="1"
				transform="translate(-50%, -50%)"
				font="--base"
				{...override('PGLabel')}
			>
				                        {"Kész"}                    
			</Text>}
			                
			<MotionBox style={{
				position: "absolute",
				top: "0",
				left: "0",
				height: "100%",
				background: "#4caf50",
				borderRadius: "5px"
			}} initial={{
				width: 0
			}} animate={{
				width: `${percentage}%`
			}} transition={{
				type: "spring",
				stiffness: 300,
				damping: 15
			}} />
			                  
            
		</Box>
		        
	</Box>;
};

export default atomize(ProgressBar)({
	name: "ProgressBar",
	description: {
		en: "ProgressBar — visually represents the progress of a task"
	},
	propInfo: {
		current: {
			control: "input",
			category: 'Main',
			weight: 1
		},
		total: {
			control: "input",
			category: 'Main',
			weight: 1
		} // ... other props

	}
}, {
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true
});