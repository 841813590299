import React from "react";
import { useOverrides } from "@quarkly/components";
import { Button, Link } from "@quarkly/widgets";
import { motion, useSpring } from "framer-motion";
const MotionButton = motion(Button);
const defaultProps = {
	"text-decoration-line": "initial",
	"display": "grid",
	"align-items": "center",
	"justify-items": "center"
};
const overrides = {};

const CtaButton = ({ ...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const rotate = useSpring(0);
	const scale = useSpring(1, {
		stiffness: 300
	});
	return <Link {...rest}>
		<MotionButton {...override('MotionButton')} style={{
			width: 150,
			height: 80,
			borderRadius: 30,
			rotate,
			scale,
			cursor: "pointer"
		}} whileHover={{
			rotate: 360,
			scale: 1.25
		}} whileTap={{
			scale: 0.75
		}}>
			Indítás
			{children}
		</MotionButton>
	</Link>;
};

Object.assign(CtaButton, { ...Button,
	defaultProps,
	overrides
});
export default CtaButton;