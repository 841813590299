import React, { useState, useEffect } from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Input, Box } from "@quarkly/widgets"; // Check values from https://us1.admin.mailchimp.com/audience/tags/lazy/

const overrides = {
	Input: {
		kind: "Input"
	},
	"MC Label": {
		kind: "Text"
	}
};

const McSubscribeCheckbox = ({
	trigger = false,
	email = "example@example.com",
	firstName = "",
	lastName = "",
	price = "",
	listId = "",
	tagName = "default-tag",
	...props
}) => {
	const {
		override,
		rest
	} = useOverrides(props, overrides);
	const [checked, setChecked] = useState(false);

	const subscribeUser = async () => {
		const url = 'https://teszt.sziklaybence.com/.netlify/functions/mc-subscribe'; // Replace with your Netlify function URL

		const data = {
			email,
			firstName,
			lastName,
			price,
			listId,
			tagName
		};

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});

			if (response.ok) {//console.log("Subscription successful");
			} else {
				const errorData = await response.json();
				console.error("Subscription failed:", errorData.message);
			}
		} catch (error) {
			console.error("Subscription error:", error.message);
		}
	};

	useEffect(() => {
		if (trigger && checked) {
			subscribeUser();
		}
	}, [trigger, checked]);
	return <Box {...rest}>
		            
		<Input type="checkbox" checked={checked} onChange={() => setChecked(!checked)} {...override("Input")} />
		            
		<Text {...override("MC Label")} />
		        
	</Box>;
};

export default McSubscribeCheckbox;